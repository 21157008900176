.account {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {

        .header {
            background-color: $primary;
            border-radius: 20px;
            height: 90px;
            margin-bottom: -30px;

            .picture {
                height: 160px;
                width: 160px;
                border-radius: 80px;
                background-color: antiquewhite;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                bottom: 60px;
            }
        }

        .body {
            background-color: white;
            border-radius: 20px;
            padding: 40px;

            .item {
                width: 30vw;
                padding-right: 30px;
                display: flex;
                justify-content: space-between;
            }

            .detail {
                width: 50vw;

                .values-recent {
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;

                    .label {
                        color: $primary;
                    }
                }

                .values-new {
                    margin-top: 10px;
                    display: flex;

                    .MuiFormControl-root {
                        width: 100%;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}