$drawer-width: 230px;
$primary: #08A79D;
$secondary: #EFECFE;
$bg: #E5E5E5;

@import '../../../node_modules/@sweetalert2/theme-material-ui/material-ui.scss';
@import "../../containers/containers.scss";

@import "../../shared/customModal/customModal.scss";

@import "../../pages/auth/auth.scss";
@import "../../pages/project/project.scss";
@import "../../pages/request/request.scss";
@import "../../pages/account/account.scss";
@import "../../pages/credit/credit.scss";

:root {
    .swal2-container {
        z-index: 3000;
    }

    .MuiBackdrop-root {
        &.loading {
            z-index: 3000;
        }
    }

    font-size: small;

    button {
        text-transform: none;
    }
}

.swal2-modal {

    background-color: $primary !important;
    color: white !important;
    border-radius: 20px !important;

    button {
        border-radius: 20px !important;
    }

    .swal2-confirm {
        background-color: white !important;
        color: $primary !important;
    }

    .swal2-footer {
        border-top: 0px !important;
    }
}

.custom-table {
    .header {
        background-color: white;
        margin-bottom: 20px;
        border-radius: 20px 20px 0px 0px;
        padding: 20px;
        display: flex;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;

            button {
                margin-left: 20px;
            }

            .options {
                width: 200px;
            }
        }
    }

    table {
        background-color: white;
        width: 100%;
        padding: 20px;

        th {
            padding: 10px;
            text-align: left;
        }

        td {
            padding: 10px;
        }
    }
}