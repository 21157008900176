.project {
    .status {
        padding: 5px 10px;
        border-radius: 10px;

        &.New {
            background-color: #E4EFFD;
            color: #1072F1;
        }

        &.Requested {
            background-color: #FEF6EC;
            color: #FFC807;
        }

        &.Approved {
            background-color: #E3FBF5;
            color: #1DC39A;
        }

        &.Returned {
            background-color: #FCEBE8;
            color: #E34A30;
        }

        &.OnProgress {
            background-color: #FFE1F8;
            color: #F755F1;
        }

        &.FiledingCompleted {
            background-color: #D9FBBF;
            color: #3DF110;
        }

        &.FieldingReturned {
            background-color: #FFCBF7;
            color: #F11038;
        }

        &.Completed {
            background-color: #F8E4FD;
            color: #1072F1;
        }
    }
}

.propose-list {
    padding: 20px;

    table {
        td {
            padding: 5px 10px;

            button {
                padding: 0px;
            }
        }
    }
}

.create {

    .supporting-file {
        border: 2px solid #c9c9c9;
        margin: 8px 0px;
        border-radius: 20px;
        padding: 10px;

        .body {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            height: 100%;
        }
    }

    .choose {
        margin: 8px 0px;
        height: 51.6px;
    }
}