.credit {
    .header {
        background-color: white;
        border-radius: 20px;
        height: 80px;
        margin-bottom: 20px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .balance {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .value {
                margin-right: 20px;
            }
        }
    }

    .status {
        padding: 5px 10px;
        border-radius: 10px;

        &.success {
            background-color: #D9FBBF;
            color: #28c600;
        }

        &.declined {
            background-color: #f89494;
            color: #c40024;
        }
    }
}