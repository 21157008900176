.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    .custom-modal-content {
        width: 60vw;

        .MuiCardHeader-root {
            background-color: $primary;
            color: white;
        }

        &.sm {
            width: 30vw;
        }

        .MuiCardHeader-root {
            span {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .MuiCardContent-root {
            max-height: 90vh;
            overflow: auto;
        }

    }
}