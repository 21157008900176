.auth {

    .css-1q60rmi-MuiAutocomplete-endAdornment {
        top: -5px !important;
    }

    .header {
        display: flex;
        align-items: center;
        height: 10vh;
        justify-content: space-between;

        ul {
            list-style: none;
            display: flex;
            flex-direction: row;

            li {
                margin-left: 5vw;
            }
        }
    }

    .body {
        display: flex;
        align-items: center;
        height: 90vh;

        p {
            margin: 20px 0px;
        }

        button {
            margin-top: 20px;
        }

        .MuiCard-root {
            padding: 50px !important;
            text-align: center;
            background-color: #ffffff90;
            backdrop-filter: blur(6px);
            max-height: 80vh;
            overflow: auto;
        }

        img {
            position: absolute;
            right: 0%;
            height: 65vh;
            width: 65vw;
            z-index: -1;
        }

        .option {
            cursor: pointer;
        }

        .active {
            background-color: $primary;
            color: white;
        }

        .state-list {

            button {
                margin-top: 0px !important;
            }

            li:hover {
                background-color: #00000020;

            }
        }
    }
}

.verification {
    h2 {
        display: flex !important;
        align-items: center;

        span {
            margin-right: 10px;
        }

        img {
            height: 50px;
        }
    }
}